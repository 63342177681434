import classNames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import { sectionImageHeight, sectionImageWidth } from '../../../styles/variables.module.scss';
import type { ImageType } from '../../../types/image';
import type { HorizontalSide, VerticalSide } from '../../../types/styles';
import { Image } from '../../image';
import { Skeleton } from '../../skeleton';
import { SectionBlock } from '../section-block';
import styles from './section-visual.module.scss';
export const SectionVisual: FunctionComponent<SectionVisualProps> = ({
  sideOnSmallScreen,
  sideOnLargeScreen,
  videoNode,
  image,
  preset,
  loading,
  fade,
  verticalCenter = false,
  fullWidth = false
}) => {
  return <SectionBlock className={classNames(styles.visual, {
    [styles.fade]: fade
  })} justify={verticalCenter ? 'center' : 'space-between'} sideOnSmallScreen={sideOnSmallScreen} sideOnLargeScreen={sideOnLargeScreen} data-sentry-element="SectionBlock" data-sentry-component="SectionVisual" data-sentry-source-file="index.tsx">
            <div>
                {videoNode && <div className={styles[`box-${preset}`]}>{videoNode}</div>}
            </div>

            {loading ? <Skeleton height={sectionImageHeight} width={sectionImageWidth} /> : image && <Image source={image.source} source2x={image.source2x} source3x={image.source3x} contentType={image.contentType} alt={image.alt} lazy={preset !== 'primary'} width={fullWidth ? '100%' : sectionImageWidth} height={fullWidth ? 'auto' : sectionImageHeight} data-testid='section.image' />}
        </SectionBlock>;
};
export type SectionVisualProps = {
  image?: ImageType;
  loading?: boolean;
  videoNode?: ReactNode;
  preset?: 'primary' | 'default';
  sideOnLargeScreen?: HorizontalSide;
  sideOnSmallScreen?: VerticalSide;
  verticalCenter?: boolean;
  fade?: boolean;
  fullWidth?: boolean;
};