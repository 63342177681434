import classNames from 'classnames';
import type { FunctionComponent } from 'react';
import type { WithTestId } from '../../../lib/utils/testid';
import type { FlexAlignment, HorizontalSide, TextAlignment, VerticalSide } from '../../../types/styles';
import styles from './section-block.module.scss';

/** Container for image, video and text elements. Flexbox. */
export const SectionBlock: FunctionComponent<React.PropsWithChildren<Props>> = ({
  sideOnSmallScreen,
  sideOnLargeScreen,
  justify = 'center',
  align = 'center',
  textAlignOnLargeScreen,
  textAlignOnSmallScreen = 'center',
  'data-testid': testId,
  children,
  className
}) => <div data-testid={testId} className={classNames(className, styles.block, styles[`justify-${justify}`], styles[`align-${align}`], styles[`text-align-small-${textAlignOnSmallScreen}`], textAlignOnLargeScreen && styles[`text-align-large-${textAlignOnLargeScreen}`], sideOnLargeScreen && styles[sideOnLargeScreen], sideOnSmallScreen && styles[sideOnSmallScreen])} data-sentry-component="SectionBlock" data-sentry-source-file="index.tsx">
        {children}
    </div>;
type Props = WithTestId<{
  /** Position on mobiles and other small screens. */
  sideOnSmallScreen?: VerticalSide;
  /** Side on tablets, desktops and other larger screens. */
  sideOnLargeScreen?: HorizontalSide;
  /** Alignment along the main axis. */
  justify?: FlexAlignment;
  /** Alignment along the cross axis. */
  align?: FlexAlignment;
  /** Alignment of text content inside on mobiles and other small screens. Defaults to browser default (left). */
  textAlignOnSmallScreen?: TextAlignment;
  /** Alignment on tablets, desktops and other larger screens. Defaults to center. */
  textAlignOnLargeScreen?: TextAlignment;
  /** Extra classname for extra special cases. */
  className?: string;
}>;