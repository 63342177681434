import type { SectionFragment } from '../../graphql/fragments/SectionFragment.graphql'

export const fragmentToVisualProps = ({
    visualSideOnLargeScreen,
    visualSideOnSmallScreen,
    visualVerticalCenter,
    visualFade,
    visualFullWidth,
}: Partial<SectionFragment>) => {
    return {
        verticalCenter: visualVerticalCenter,
        fade: visualFade,
        sideOnLargeScreen:
            visualSideOnLargeScreen === 'left' ? 'left' : 'right',
        sideOnSmallScreen: visualSideOnSmallScreen === 'top' ? 'top' : 'bottom',
        fullWidth: visualFullWidth,
    } as const
}
